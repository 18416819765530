import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  static values = {
    siteKey: String,
  };

  connect() {
    this.buttonsData = [];
    this.disabledButtons();

    const component = this;
    turnstile.ready(function () {
      component.validate();
    });
  }

  validate() {
    turnstile.render(this.element, {
      sitekey: this.siteKeyValue,
      appearance: "interaction-only",
      theme: "light",
      language: "pt-br",
      "response-field-name": "captcha-token",
      callback: () => {
        this.enableButtons();
      },
    });
  }

  disabledButtons() {
    this.buttonTargets.forEach((button, index) => {
      this.buttonsData[index] = button.innerHTML;

      button.setAttribute("disabled", true);
      button.innerHTML = button.getAttribute("data-disabled");
    });
  }

  enableButtons() {
    this.buttonTargets.forEach((button, index) => {
      button.removeAttribute("disabled");
      button.innerHTML = this.buttonsData[index];
    });
  }
}
